<template>
  <div class="content content_in">
    <form class="row">
      <div class="col-12 col-xl-6">
        <div class="title-row row justify-content-between">
          <div class="col-12 col-md-auto">
            <h1>
              {{ $t("platform.edit_platform") }}
            </h1>
          </div>
        </div>

        <div class="sep _h10"></div>

        <div class="row">
          <div class="col-12">
            <div class="form-item">
              <div class="form-item__label">
                {{ $t("platform.arbitrage.name") }}
              </div>
              <div class="form-item__field">
                <input ref="name" v-model="platform.name" type="text" :class="{ invalid: $v.platform.name.$error }" />
                <small v-if="$v.platform.name.$error && (!$v.platform.name.minLength || platform.name === '')" class="error">{{ $t("min_len_pass", { number: "3" }) }}</small>
                <small v-if="$v.platform.name.$error && !$v.platform.name.maxLength" class="error">{{
                  $t("max_len_pass", {
                    number: 52,
                  })
                }}</small>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-item">
              <div class="form-item__label info-popup">
                {{ $t("platform.postback") }}
                <span
                  v-tooltip="{
                    content: $t('platform.postback_pop_up'),
                    trigger: setTooltipTrigger,
                  }"
                >
                  <svg v-svg symbol="info" size="0 0 17 17" role="info"></svg>
                </span>
              </div>
              <div class="form-item__field mb-2">
                <TraffbackWithMacros class="p-0" v-model="platform.postback_url" @addMacros="addMacros" :macroses="macroses" :invalid="$v.platform.postback_url.$invalid" :title="'postback_url'" />
              </div>
              <div class="form-item__field mb-2">
                <div class="form-item__label">
                  {{ $t("platform.send_postback_to") }}
                </div>

                <custom-multiselect :list="postback_format_list" v-model="postback_format" :allowEmpty="true" :multiply="true">
                  <template v-slot:block="props">
                    <span>
                      {{ props.value.title }}
                    </span>
                  </template>
                  <template v-slot:list="props">
                    <span>
                      {{ props.value.title }}
                    </span>
                  </template>
                </custom-multiselect>
              </div>
            </div>

            <label>{{ $t("platforms.ready_templates") }}</label>
            <custom-multiselect v-model="templateModel" :list="templates" class="templates mt-1" :allowEmpty="true" :searchBy="'name'">
              <template v-slot:block="props">
                <span v-if="props.value">
                  {{ props.value.name }}
                </span>
              </template>
              <template v-slot:list="props">
                <span>
                  {{ props.value.name }}
                </span>
              </template>
            </custom-multiselect>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <div class="form-item__field">
              <button type="submit" @click.prevent="createArbitrage" class="btn _mob100">
                {{ $t("platform.save_platform") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ServiceSubAccs from "@/api/subaccsService";
import "@/assets/css/_page-platform.scss";
import "@/assets/css/_page-add-platform.scss";
import "@/assets/css/_section.scss";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, url } from "vuelidate/lib/validators";
import LandingMixin from "@/mixins/landModal";
import addMacros from "@/mixins/addMacros";
import ServicePanel from "@/api/servicePanel";
import TraffbackWithMacros from "@/components/TraffbackWithMacros.vue";

export default {
  name: "Arbitrage",
  components: {
    TraffbackWithMacros,
  },
  mixins: [validationMixin, LandingMixin, addMacros],
  data() {
    return {
      isEdit: false,
      currentPlatform: null,

      landings: [],
      platform: {
        complexity: 3,
        lands: [],
        fullscreen: false,
        name: "",
        redirect_type: { title: "platform.arbitrage.redirect", value: 0 },

        domain_rotation: {
          title: "platform.arbitrage.random",
          value: "random",
        },
        payment_model: {
          title: "Revshare",
          value: 0,
        },
        tb_subscription: "default",
        tb_complexity: "partners",
        tb_subscription_url: "",
        tb_complexity_url: "",
        postback_url: "",
        enable_onclick_ads: false,
        redirect_to_actual_domain: true,
        enable_in_page_ads: true,
        feedsSales: false,
        mailing_frequency: { title: "Standard", value: "standart" },
      },
      redirectMode: [
        { title: "platform.arbitrage.window_accept", value: 1 },
        { title: "platform.arbitrage.redirect", value: 0 },
        { title: "platform.arbitrage.on_site_click", value: 2 },
      ],
      rotationMode: [
        { title: "platform.arbitrage.random", value: "random" },
        { title: "platform.arbitrage.new_to_old", value: "newest-to-oldest" },
      ],
      tabs: ["all", "adult", "mainstream"],
      currentTab: "all",
      mailings: [
        { title: "Standard", value: "standart" },
        { title: "Maximum", value: "maximum" },
      ],
      paymentOptions: [
        {
          title: "CPL",
          value: 1,
        },
        {
          title: "Revshare",
          value: 0,
        },
      ],
      order: "grid",
      templateModel: null,
      templates: [],
      postback_format: [],
      postback_format_list: [
        {
          title: "Push",
          id: 0,
          value: "push",
        },
        {
          title: "InPage",
          id: 1,
          value: "in_page",
        },
        {
          title: "OnClick",
          id: 2,
          value: "on_click",
        },
        {
          title: "TB Leads",
          id: 3,
          value: "tb_leads",
        },
      ],
    };
  },
  validations: {
    platform: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(52),
      },
      postback_url: {
        url,
        maxLength: maxLength(4096),
      },
    },
  },
  computed: {
    siteVideoGuide() {
      return this.$i18n.locale === "ru" ? "https://youtu.be/aVCowBx33FA" : "https://youtu.be/OQkjFenNb9E";
    },

    //arbitrage
    setTooltipTrigger() {
      return window.innerWidth > 768 ? "hover" : "click";
    },
    filteredLandings() {
      if (this.currentTab === "adult") {
        return this.landings.filter((el) => el.category == 1);
      } else if (this.currentTab === "mainstream") {
        return this.landings.filter((el) => el.category == 2);
      } else return this.landings.map((el) => el);
    },
  },
  methods: {
    getUrlTemplates() {
      ServicePanel.getUrlTemplates().then((res) => {
        if (res && res.status === 200) {
          this.templates = res.data;
        }
      });
    },
    setPostbackValue() {
      const map = new Map([
        ["push", "enable_postback_push_revshare"],
        ["in_page", "enable_postback_in_page_revshare"],
        ["on_click", "enable_postback_on_click_revshare"],
        ["tb_leads", "enable_postback_tb_leads"],
      ]);

      map.forEach((value, key) => {
        if (this.currentPlatform[value]) {
          this.postback_format.push(this.postback_format_list.find((el) => el.value === key));
        }
      });
    },
    checkPostbackValue(format) {
      return this.postback_format.some((el) => el.value === format);
    },
    displayOptionHint(value) {
      if (value === "random") return this.$t("platform.arbitrage.random_hint");
      else return this.$t("platform.arbitrage.new_to_old_hint");
    },
    formatLabel(el) {
      return this.$t(el);
    },
    setCurrentTab(tab) {
      this.currentTab = tab;
    },
    addLandingId(el) {
      if (this.platform.lands.find((ind) => ind === el.id)) {
        this.platform.lands = this.platform.lands.filter((ind) => ind !== el.id);
      } else {
        this.platform.lands.push(el.id);
      }
    },
    createArbitrage() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$gtm.trackEvent({ event: "create_source_disabled_button" });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      }

      this.$gtm.trackEvent({ event: "create_source_submited" });

      const payload = {
        site: 0,
        name: this.platform.name,
        payment_model: this.platform.payment_model.value,
        complexity_redirect: this.platform.redirect_type.value,
        mailing_frequency: this.platform.mailing_frequency.value,
        tb_subscription: this.platform.tb_subscription,
        tb_complexity: this.platform.tb_complexity,
        complexity: this.platform.complexity,
        tb_subscription_url: this.platform.tb_subscription === "user" ? this.platform.tb_subscription_url : undefined,
        tb_complexity_url: this.platform.tb_complexity === "user" ? this.platform.tb_complexity_url : undefined,
        lands: this.platform.lands,
        enable_onclick_ads: this.platform.enable_onclick_ads,
        enable_in_page_ads: this.platform.enable_in_page_ads,
        redirect_to_actual_domain: +this.platform.redirect_to_actual_domain,
        postback_url: this.platform.postback_url,
        fullscreen: +this.platform.fullscreen,
        deny_sell_to_feeds: this.platform.payment_model.value === 0 ? +this.platform.feedsSales : 0,

        redirect_to_personal_domain_settings: {
          active: this.platform.redirect_to_actual_domain,
          strategy: this.platform.redirect_to_actual_domain ? this.platform.domain_rotation.value : undefined,
        },
        enable_postback_in_page_revshare: this.checkPostbackValue("in_page"),
        enable_postback_on_click_revshare: this.checkPostbackValue("on_click"),
        enable_postback_push_revshare: this.checkPostbackValue("push"),
        enable_postback_tb_leads: this.checkPostbackValue("tb_leads"),
      };

      if (this.isEdit) {
        if (!this.$v.$invalid) {
          let params = {
            // general variables for platforms
            name: this.currentPlatform.name,
            complexity: +this.currentPlatform.complexity,
            tb_subscription: this.currentPlatform.tb_subscription,
            tb_complexity: this.currentPlatform.tb_complexity,
            tb_subscription_url: this.currentPlatform.tb_subscription_url,
            tb_complexity_url: this.currentPlatform.tb_complexity_url,
            mailing_frequency: this.currentPlatform.mailing_frequency,
            adult: +this.currentPlatform.adult,
            fullscreen: +this.currentPlatform.fullscreen,
            lands: this.currentPlatform.lands || [],
            postback_url: this.currentPlatform.postback_url,
            is_direct_link: this.currentPlatform.direct_link || false,
            traffic_type: this.currentPlatform.traffic_type || null,
          };
          if (!this.currentPlatform.site) {
            params.payment_model = +this.currentPlatform.payment_model.value;
          }
          params = Object.assign(params, payload);

          ServiceSubAccs.updateSubAcc({
            id: this.$route.params.id,
            params,
          }).then((res) => {
            if (res) {
              if (res.status === 200) {
                this.$alert({
                  title: this.$t("sent_success"),
                  text: res.message,
                  type: "success",
                });
                this.$router.push("/directlink");
              } else {
                this.$alert({
                  title: this.$t("sent_error"),
                  text: res.message,
                });
              }
            }
          });
          this.$v.$reset();
        }
      } else {
        if (!this.$v.$invalid) {
          ServiceSubAccs.createSubAcc(payload).then((res) => {
            if (res) {
              if (res.status === 200) {
                this.$gtm.trackEvent({ event: "create_source_success" });
                this.$alert({
                  title: this.$t("sent_success"),
                  text: res.message,
                  type: "success",
                });
                this.$router.push("/platforms");
              } else {
                this.$gtm.trackEvent({ event: "create_source_failure" });
                this.$alert({
                  title: this.$t("sent_error"),
                  text: res.message,
                });
              }
            }
          });
        }
      }
    },
  },
  watch: {
    "platform.enable_onclick_ads": function (val) {
      if (val) this.platform.fullscreen = false;
    },
    templateModel(val) {
      if (val) {
        const selectedTemplate = this.templates.find((el) => el.name == val.name);
        this.platform.postback_url = selectedTemplate.postback_url;
      } else {
        this.platform.postback_url = "";
      }
    },
  },
  mounted() {
    this.getUrlTemplates();

    if (this.$route.fullPath.includes("edit-platform")) {
      this.isEdit = true;
    }

    ServiceSubAccs.getLandings().then((res) => {
      if (res && res.status === 200) {
        this.landings = res.data;
      }
    });

    if (this.isEdit) {
      ServiceSubAccs.getSubAcc(this.$route.params.id)
        .then((res) => {
          if (res && res.status === 200) {
            this.currentPlatform = res.data;
          }
        })
        .then(() => {
          this.platform = {
            complexity: this.currentPlatform.complexity,
            payment_model: this.currentPlatform.payment_model == 1 ? { title: "CPL", value: 1 } : { title: "Revshare", value: 0 },

            redirect_type: this.redirectMode.find((el) => el.value === this.currentPlatform.complexity_redirect),

            mailing_frequency: this.mailings.find((el) => el.value === this.currentPlatform.mailing_frequency),

            tb_subscription: this.currentPlatform.tb_subscription,
            tb_complexity: this.currentPlatform.tb_complexity,
            lands: this.currentPlatform.lands || [],
            fullscreen: this.currentPlatform.fullscreen,
            enable_in_page_ads: this.currentPlatform.enable_in_page_ads,
            name: this.currentPlatform.name,
            tb_subscription_url: this.currentPlatform.tb_subscription_url,
            tb_complexity_url: this.currentPlatform.tb_complexity_url,
            postback_url: this.currentPlatform.postback_url,
            enable_onclick_ads: this.currentPlatform.enable_onclick_ads,
            feedsSales: this.currentPlatform.deny_sell_to_feeds,
            redirect_to_actual_domain: this.currentPlatform.redirect_to_actual_domain,

            domain_rotation: this.rotationMode.find((el) => el.value === this.currentPlatform.redirect_to_personal_domain_settings.strategy) || this.rotationMode[0],
          };
          this.setPostbackValue();
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.land-tabs ::v-deep li {
  width: 33%;
}

.option-hint {
  width: 95%;
  padding-top: 4px;
  white-space: normal;
}

@media screen and (max-width: 1200px) {
  .d-none-mobile {
    display: none;
  }
}

.info-popup.w-160 {
  width: 160px;
}

.h-fit {
  height: fit-content;
}

.fix-height {
  padding-top: 30px;
  height: 130px;
  .form-item__field {
    margin-bottom: 4px;
  }
}
.small-height {
  padding-top: 42px;
}

.form-item__field label.disabled {
  cursor: not-allowed;
}

.warn-block {
  display: flex;
  align-items: start;
  column-gap: 6px;
  p {
    margin: 0;
    font-size: 14px;
    color: #626262;
  }
  svg {
    width: 40px;
    fill: #626262;
  }
}
.gap-3 {
  gap: 16px;
}

.invalid {
  border: 1px solid red;
}

.complex-select ::v-deep .multiselect__content-wrapper {
  max-height: 175px !important;
}

.traffback {
  display: flex;
  column-gap: 16px;
  row-gap: 16px;
}

@media screen and (max-width: 768px) {
  .traffback {
    flex-flow: column wrap;
  }
}

.multiselect.payment {
  &::v-deep {
    .multiselect__content-wrapper li {
      color: #000;
    }
  }
}

.links-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
  width: 100%;

  img {
    width: 125px;
  }

  a {
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 66px;
    gap: 0 4px;
    padding: 12px 26px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    svg {
      fill: #626262;
    }
  }

  @media (max-width: 576px) {
    img {
      width: auto;
    }

    a {
      padding: 8px;
    }
  }
}

.checkbox-switch__text {
  width: fit-content;
}

.checkbox-arbitrage-wrapper {
  @media (min-width: 576px) {
    justify-content: end;
  }
}

.disabled_cpl {
  &::v-deep {
    .custom-multiselect-list .custom-multiselect-list__block:first-child {
      pointer-events: none;
      background: #ededed !important;
      color: #676767 !important;
    }
  }
}
</style>
